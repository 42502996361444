<div id="principal-informationl">
    <div class="step-content">
        <div class="header" [ngStyle]="{ 'background-color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[5] }">
            <h1  [ngStyle]="{ 'color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] }">
                    Informations principales</h1>
        </div>

        <div class="title">
            <span>
              <img src="/assets/principal-information/logement.svg" alt="Logement" >
            </span>
            <h1 [ngStyle]="{'color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[0]}">Logement</h1>
        </div>

        <form [formGroup]="informationForm">
            <div class="form-content">
                <!-- Input Code postal-->
                <div class="field-container">
                    <mat-label  ><span class="text-danger">*</span> Code postal logement</mat-label>
                    <mat-form-field class="field-element" appearance="outline">
                        <input matInput type="text" pattern="[0-9]*" id="postalCode" name="postalCode" formControlName="postalCode" maxLength="5" min="1" (valuesChange)="postalCodeChange" (keydown)="onKeyDown($event)">
                        <mat-error *ngIf="informationForm.controls.postalCode.invalid">
                            <mat-error *ngIf="informationForm.controls.postalCode.errors?.required">
                                Code postal logement est requis.
                            </mat-error>
                        </mat-error>
                        <mat-spinner *ngIf="loadingCities" matSuffix color="primary" [diameter]="20"></mat-spinner>
                    </mat-form-field>
                </div>
                <!-- Input Select Ville-->
                <div class="field-container">
                    <mat-label><span class="text-danger">*</span> Ville logement</mat-label>
                    <mat-form-field class="field-element" appearance="outline">
                        <mat-select id="city" name="city" required formControlName="city">
                            <mat-option *ngFor="let city of cities.city" [value]="city">{{city}}</mat-option>
                            <ng-container *ngIf="cities.city.length == 0 && informationForm.value.city">
                                <mat-option [value]="informationForm.value.city">{{informationForm.value.city}}</mat-option>
                            </ng-container>
                        </mat-select>

                        <mat-error *ngIf="informationForm.controls.city.invalid">
                            Ville logement est requis.
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>
            <div class="selection-box">
                <h1 [ngClass]="{'text-danger': informationForm.controls.typeHabitation.invalid && informationForm.controls.typeHabitation.touched}"><span class="text-danger">*</span> Type d’habitation</h1>
                <input type="hidden" name="habitation" formControlName="typeHabitation">
                <div class="checkbox-types">
                    <div class="checkbox-content" 
                    [ngStyle]="{'border': principalInformation.typeHabitation == 'Appartement' ? '1px solid ' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}" 
                    (click)="selectHabitation('Appartement')">
                   <img src="/assets/principal-information/appartement.svg" alt="appartement">
                   <h2>Appartement</h2>
                   <span class="checkbox-selected-hide" [ngClass]="{'checkbox-selected': principalInformation.typeHabitation == 'Appartement'}">
                    <mat-icon [ngStyle]="{'color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] || 'none'}">check</mat-icon>                   </span>
               </div>
               
                    <div class="checkbox-content" [ngStyle]="{'border': principalInformation.typeHabitation == 'Maison' ? '1px solid ' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}"  (click)="selectHabitation('Maison')">
                        <img src="/assets/principal-information/maison.svg" alt="maison">
                        <h2>Maison</h2>
                        <span class="checkbox-selected-hide" [ngClass]="{'checkbox-selected': principalInformation.typeHabitation == 'Maison'}">
                            <mat-icon [ngStyle]="{'color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] || 'none'}">check</mat-icon>                   
                        </span>
                    </div>

                </div>
                <h1 class="mt-4" [ngClass]="{'text-danger': informationForm.controls.typeProspect.invalid && informationForm.controls.typeProspect.touched}"><span class="text-danger">*</span> Vous êtes un ?</h1>
                <input type="hidden" name="typeProspect" formControlName="typeProspect">
                <div class="min-selection-box d-flex justify-content-around flex-wrap">
                    <div class="min-checkbox-content d-flex justify-content-center align-items-center m-3 px-1" [ngStyle]="{'border': principalInformation.typeProspect == 'Locataire' ? '1px solid ' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}"  (click)="selectClientType('Locataire')">
                        <span class="text-center">Locataire</span>
                        <span class="min-checkbox-selected-hide" [ngClass]="{'min-checkbox-selected': principalInformation.typeProspect == 'Locataire'}">
                          <img src="/assets/global/check-icon.svg" alt="Check">
                        </span>
                    </div>
                    <div class="min-checkbox-content d-flex justify-content-center align-items-center m-3 px-1" [ngStyle]="{'border': principalInformation.typeProspect == 'Propriétaire occupant' ? '1px solid ' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}" (click)="selectClientType('Propriétaire occupant')">
                        <span class="text-center">Propriétaire occupant</span>
                        <span class="min-checkbox-selected-hide" [ngClass]="{'min-checkbox-selected': principalInformation.typeProspect == 'Propriétaire occupant'}">
                          <img src="/assets/global/check-icon.svg" alt="Check">
                        </span>
                    </div>
                    <div class="min-checkbox-content d-flex justify-content-center align-items-center m-3 px-1" [ngStyle]="{'border': principalInformation.typeProspect == 'Propriétaire non occupant' ? '1px solid ' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}"  (click)="selectClientType('Propriétaire non occupant')">
                        <span class="text-center">Propriétaire non occupant</span>
                        <span class="min-checkbox-selected-hide" [ngClass]="{'min-checkbox-selected': principalInformation.typeProspect == 'Propriétaire non occupant'}">
                          <img src="/assets/global/check-icon.svg" alt="Check">
                        </span>
                    </div>
                </div>
                <h1 class="mt-4" [ngClass]="{'text-danger': informationForm.controls.residence.invalid && informationForm.controls.residence.touched}"><span class="text-danger">*</span> S'agit-il d’une ?</h1>
                <input type="hidden" name="residence" formControlName="residence">

                <div class="min-selection-box d-flex justify-content-around flex-wrap" name="">
                    <div class="min-checkbox-content d-flex justify-content-center align-items-center m-3 px-1" [ngStyle]="{'border': principalInformation.residence == 'Résidence principale' ? '1px solid ' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}" (click)="selectResidence('Résidence principale')">
                        <span class="text-center">Résidence principale</span>
                        <span class="min-checkbox-selected-hide" [ngClass]="{'min-checkbox-selected': principalInformation.residence == 'Résidence principale'}">
                          <img src="/assets/global/check-icon.svg" alt="Check">
                        </span>
                    </div>
                    <div class="min-checkbox-content d-flex justify-content-center align-items-center m-3 px-1" [ngStyle]="{'border': principalInformation.residence == 'Résidence secondaire' ? '1px solid' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}" (click)="selectResidence('Résidence secondaire')">
                        <span class="text-center">Résidence secondaire</span>
                        <span class="min-checkbox-selected-hide" [ngClass]="{'min-checkbox-selected': principalInformation.residence == 'Résidence secondaire'}">
                          <img src="/assets/global/check-icon.svg" alt="Check">
                        </span>
                    </div>
                    <!-- <div class="min-checkbox-content d-flex justify-content-center align-items-center m-3 px-1" [ngStyle]="{'border': principalInformation.residence == 'Location à titre gratuit' ? '1px solid' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}" (click)="selectResidence('Location à titre gratuit')">
                        <span class="text-center">Location à titre gratuit</span>
                        <span class="min-checkbox-selected-hide" [ngClass]="{'min-checkbox-selected': principalInformation.residence == 'Location à titre gratuit'}">
                          <img src="/assets/global/check-icon.svg" alt="Check">
                        </span>
                    </div> -->
                </div>
            </div>


            <div class="selection-box mt-3" *ngIf="principalInformation.residence == 'Résidence secondaire' ? true : false ">
                <h1 class="d-flex justify-content-center align-items-center">Avez-vous déjà un contrat d'assurance MRH de votre résidence principale chez AS SOLUTIONS ?
                    <span class="ml-3 d-inline-block">
                        <div class="container-switch">
                          <label class="switch space-switch"><input type="checkbox" id="contrat" name="contrat" formControlName="contrat" ><span class=""><span class="slider-text-checked">Oui</span>
                    <span class="slider-text-unchecked">Non</span>
                    </span>
                    </label>
            </div>
            </span>
            </h1>
            <div class="d-flex justify-content-center mt-4" *ngIf="informationForm.value.contrat">
                <div class="field-container text-left">
                    <mat-label><span class="text-danger">*</span> N° contrat</mat-label>
                    <mat-form-field class="field-element" appearance="outline" [ngStyle]="{'border-color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[0]}">
                        <input matInput id="num_contrat" name="num_contrat" formControlName="num_contrat" (input)="onInputNumContratChange($event)">
                        <mat-error *ngIf="informationForm.controls.num_contrat.invalid">
                            <mat-error *ngIf="informationForm.controls.num_contrat.errors?.required">
                                N° contrat est requis.
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            
    </div>

    <!-- Input Surface de logement-->
    <div class="d-flex justify-content-center my-3">
        <div class="field-container text-left">
            <mat-label><span class="text-danger">*</span> Surface de logement</mat-label>
            <mat-form-field class="field-element" appearance="outline">
                <input matInput type="number" id="surface" name="taille_habitation" formControlName="taille_habitation" (input)="onInputTailleHabitationChange($event)" min="1" maxLength="8" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); validity.valid||(value=value);">
                <span matSuffix class="secondairy-text">m²</span>
                <mat-error *ngIf="informationForm.controls.taille_habitation.invalid">
                    <mat-error *ngIf="informationForm.controls.taille_habitation.errors?.required">
                        Surface de logement est requis.
                    </mat-error>
                    <!-- <mat-error *ngIf="informationForm.controls.surface.errors?.max">
                        Nous sommes désolés, nous ne pouvons pas assurer ce type d'habitation !
                    </mat-error> -->
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    </form>

</div>
</div>