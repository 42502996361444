import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';




@Injectable({
  providedIn: 'root', 
})
export class ApiAuthentificationService {

  protected basePath = environment.API_URL;
  public defaultHeaders = new HttpHeaders();
 
  alerts: any;
  buttons: any;
  configuration: any;

  constructor(protected httpClient: HttpClient,

    private router: Router,
    
) {
    
   
  }

 
  public getParterShipIdToken(apiKey: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (apiKey === null || apiKey === undefined) {
      throw new Error('Required parameter apiKey was null or undefined when calling loginGet.');
    }

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', "Basic " + apiKey);



    return this.httpClient.request<any>('post', `${this.basePath}/login`,
      {
        headers: headers,
        observe,
        reportProgress,
      }
    );
  } 

  public GetOrganismeById(apiKey: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (apiKey === null || apiKey === undefined) {
        throw new Error('Required parameter apiKey was null or undefined when calling GetOrganismeById.');
    }



    return this.httpClient.request<any>('get', `${this.basePath}/globalRoutes/get_branding`, {
        observe: observe,
        reportProgress: reportProgress,
    });
}



 
 
}
