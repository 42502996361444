import { PrincipalInformationService } from './_services/principal-information.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { PrincipalInformation } from './_models/PrincipalInformation';
import { City } from './_models/City';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorDialogComponent } from 'src/app/shared-components/error-dialog/error-dialog.component';
import { ErrorDialogService } from 'src/app/shared-components/error-dialog/error.dialog.service';
import { OrgInfoService } from 'src/app/services/organisme/org-info.service';
@Component({
  selector: 'principal-information',
  templateUrl: './principal-information.component.html',
  styleUrls: ['./principal-information.component.scss']
})
export class PrincipalInformationComponent implements OnInit {
  @Output() emitFormPrincipalInformation: EventEmitter<boolean> = new EventEmitter<boolean>();

  principalInformation: PrincipalInformation = new PrincipalInformation();

  @Input() informationForm: FormGroup = new FormGroup({});
  cities: City= new City();
  loadingCities: boolean = false;
  orgInfo : any
  constructor(private _principalInformationService:PrincipalInformationService,
    private _errorDialogService: ErrorDialogService,
    private orgInfoService: OrgInfoService) { }

  ngOnInit(): void {
    this.orgInfoService.orgInfo$.subscribe((info) => {
      this.orgInfo = info;
    console.log(info)
    }); 

    if (this.informationForm.value)
    this.principalInformation = this.informationForm.value;
    this.informationForm.controls.postalCode.valueChanges.subscribe(()=>{
      if(this.informationForm.controls.postalCode.valid && (this.informationForm.controls.postalCode.value).toString().length > 4){
        this.getCityByZipCode(this.informationForm.controls.postalCode.value)
      }
    })
    this.informationForm.controls.taille_habitation.valueChanges.subscribe(()=>{
      if(this.informationForm.controls.taille_habitation.errors?.max){
        this._errorDialogService.openErrorDialog("Nous sommes désolés, nous ne pouvons pas assurer ce type d'habitation !", 'Fermer',true)
      }
    })

    this.informationForm.controls.contrat.valueChanges.subscribe(formValue => {
      if(formValue) {
        this.informationForm.controls.num_contrat.setValidators([Validators.required, Validators.pattern('[a-zA-Z0-9/]*')]);
      }
      else{
        //this.informationForm.controls.num_contrat.reset();
        this.informationForm.controls.num_contrat.clearValidators();
        this.informationForm.controls.num_contrat.setValue("");
      }
    })
  }

  onInputNumContratChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9/]/g, ''); // Remove any characters that are not allowed
    inputElement.value = sanitizedValue;

    // Update the form control value
    this.informationForm.controls.num_contrat.setValue(sanitizedValue);
  }

  onInputTailleHabitationChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    if (inputValue.length == 1 && parseInt(inputValue) == 0) {
      this.informationForm.controls.taille_habitation.setValue("");
    }
  }


  selectHabitation(type: string) : void{
    this.informationForm.controls.typeHabitation.setValue(type);
    this.principalInformation.typeHabitation = type;
  }

  selectClientType(type: string): void{
    this.informationForm.controls.typeProspect.setValue(type);
    this.principalInformation.typeProspect = type;
  }

  selectResidence(type: string): void{
    this.informationForm.controls.residence.setValue(type);
    this.principalInformation.residence = type;
    if (this.principalInformation.residence == 'Résidence principale') {
      this.informationForm.controls.contrat.setValue(false) ; 
      this.informationForm.controls.num_contrat.setValue("") ; 
    }
  }

  getCityByZipCode(postalCode: string): void{
    this.loadingCities = true
    this._principalInformationService.getCityByZipCode(postalCode)
    .subscribe((listVille: City)=>{
      this.cities = listVille;
      this.informationForm.controls.city.setValue(this.cities.city[0]);
      this.loadingCities = false;
    },(error: HttpErrorResponse)=>{
      this.cities.city = [];
      this.informationForm.controls.city.setValue("");
      this.loadingCities = false;
      if(error?.error?.message)
      this._errorDialogService.openErrorDialog(error.error.message, 'Fermer',true)
    })
  }

  limitLength(element: any, maxLength: any) {
    if (element.value.length > maxLength) {
      element.value = element.value.slice(0, maxLength);
    }
  }

  postalCodeChange() : void {
    if (this.informationForm.controls.postalCode.value.length == 5) {
      this.getCityByZipCode(this.informationForm.controls.postalCode.value) ; 
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight'];
    if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
      return;
    }
    if (allowedKeys.indexOf(event.key) === -1) {
      event.preventDefault();
    }
  }
}
