import { Component, OnInit } from '@angular/core';
import { OrgInfoService } from 'src/app/services/organisme/org-info.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {
  orgInfo: any;
  logo: any;
  constructor(
    private orgInfoService: OrgInfoService,
   ) { }

  ngOnInit(): void {
    this.orgInfoService.orgInfo$.subscribe((info) => {
      this.orgInfo = info;
      this.logo=this.orgInfo.organisme.logo
      console.log('Logo URL:', this.logo);
    }); 
  }


}

