import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiAuthentificationService } from '../ApiAuthentification/api-authentification.service';

@Injectable({
  providedIn: 'root'
})
export class OrgInfoService {

  private orgInfoSubject = new BehaviorSubject<any>(null);  
  orgInfo$ = this.orgInfoSubject.asObservable();  

  constructor(private apiService : ApiAuthentificationService) {}

  setOrgInfo(info: any) {
    this.orgInfoSubject.next(info);  
  }

  getOrgInfo() {
    return this.orgInfoSubject.getValue();  
  }


  fetchOrganizationInfo(apiKey : any) {
    

    this.apiService.GetOrganismeById(apiKey).subscribe(
      (orgData) => {
        this.setOrgInfo(orgData);  
      },
      (error) => {
        console.error('Error fetching organization info:', error);
      }
    ); 
  }
}
