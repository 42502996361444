import { GenerateDevis } from './_models/GenerateDevis';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild,Output,EventEmitter, Renderer2, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ErrorDialogService } from 'src/app/shared-components/error-dialog/error.dialog.service';
import { ComplementaryInformation } from '../complementary-information/_models/ComplementaryInfo';
import { Questions } from '../questions/_models/Questions';
import { Tarification } from './_models/Tarification';
import { PagesService } from './_service/pages.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TarificationResponse } from './_models/TarificationResponse';
import * as storageService from './_utils.ts/dataStorage'
import { GenerateDevisResponse } from './_models/GenerateDevisResponse';
import { formatDate } from '@angular/common';
import { LoadingScreenService } from 'src/app/shared-components/loading-screen/loading-screen.service';
import { environment } from 'src/environments/environment';
import { GetInfoSouscription } from './_models/GetInfoSouscription';
import { InfoSouscriptionResponse } from './_models/InfoSouscriptionResponse';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrgInfoService } from 'src/app/services/organisme/org-info.service';


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("stepper") stepper: any;

  isLinear:  boolean = false;
  stepIndex: number  = 0;
  private unsubscriber: Subject<void> = new Subject<void>();

  /**
   * Déclaration des variables de vérification la validation des formes groupes
   */
  formPrincipalInformation: boolean = false;
  formComplementaryInformation: boolean = false;
  formQuestions: boolean = false;
  formTarification: boolean = false;

  informationForm: FormGroup = this._formBuilder.group({
    postalCode: new FormControl("", [Validators.required]),
    city: new FormControl("", Validators.required),
    taille_habitation: new FormControl("", [Validators.required, Validators.min(1), Validators.max(450.0000)]),
    contrat: new FormControl(false),
    num_contrat: new FormControl(""),
    typeProspect: new FormControl("", Validators.required),
    typeHabitation: new FormControl("", Validators.required),
    residence: new FormControl("", Validators.required),
  });

  complemnetaryInformation: ComplementaryInformation = new ComplementaryInformation();
  isComplemnetaryInformationValid: boolean = false;
  isComplemnetaryInformationEtatAgeValid: boolean = false;
  isComplemnetaryInformationClicked: boolean = false;

  questions: Questions = new Questions() ;
  isQuestionsValid: boolean = false ;
  isSurfaceKiosqueValide: boolean | null = null ;
  tarification: Tarification = new Tarification() ;
  tarificationLoading: boolean = false ;
  isQuestionsClicked: boolean = false;

  tarificationResponse : TarificationResponse = new TarificationResponse() ;
  generateDevis: GenerateDevis = new GenerateDevis();
  getInfoSouscription: GetInfoSouscription = new GetInfoSouscription() ;
  infoSouscriptionResponse: InfoSouscriptionResponse = new InfoSouscriptionResponse() ;
  devisDownloadLoading: boolean = false;
  devisSendingLoading: boolean = false;
  activateLoadingScreen: boolean = false ;

  disableButtonsDevis: boolean = false ; 
  disableInfoSouscripteurInputs: boolean = false ; 
  orgInfo: any;
  
  constructor(private _formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _routes: Router,
    private _errorDialogService: ErrorDialogService,
    private _pagesService: PagesService,
    private _cookieService: CookieService,
    public loadingScreenService: LoadingScreenService,
    private orgInfoService: OrgInfoService,private renderer: Renderer2, private el: ElementRef) { }
 

  
  ngOnDestroy(): void {
    // if(this._cookieService.check('xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD')){
    //   this._cookieService.delete('xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD');
    // }
    // if(this._cookieService.check('stepIndex')){
    //   this._cookieService.delete('stepIndex');
    // }
    this.unsubscriber.next();
    this.unsubscriber.complete();


  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange
      .subscribe((res: any) => {
        this.stepIndex = res.selectedIndex;
        storageService.encryptAndStore(this.stepIndex.toString(),'xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD',this._cookieService) ;
        // this._cookieService.set('stepIndex', this.stepIndex.toString());
      });

     
  }

  updateFavicon(logoUrl: string) {
    const favicon = document.getElementById('dynamic-favicon') as HTMLLinkElement;
    
    if (favicon) {
      if (logoUrl){
        favicon.href = logoUrl;
      }else{
        favicon.href = 'assets/images/favic-2.png';
      }
    
    }
  }
  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      if(params.partnership_id){
        storageService.encryptAndStore(params.partnership_id,'6GrL131shBI6OCImJkM1v8UkVeWeXYlKdD3Khp8',this._cookieService) ;
        // this._cookieService.set('apiKey', params.partnership_id);
        this.orgInfoService.fetchOrganizationInfo(params.partnership_id)
      }else{
        //this._routes.navigate([''])
        window.open(environment.DISTRIBUTEUR_URL_PROD, "_self")
      }
    });

    this.loadDateFromCookies() ;
    if (this.stepIndex == 4 || this.stepIndex == 5) {
      this._routes.navigate(['/souscription']) ;
    }
    
    history.pushState(null, '');

    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((event) => {
        if(this.stepIndex === 3){ 
          this.stepIndex = 2 ;
        } else if(this.stepIndex === 2){
          if (this._cookieService.check('hgI2Zz6HvgNYhMNxNHeptp061gdn4yM1zJC7'))
          storageService.encryptAndStore(JSON.stringify(this.questions),'hgI2Zz6HvgNYhMNxNHeptp061gdn4yM1zJC7',this._cookieService) ;
          this.stepIndex = 1 ;
        } else if(this.stepIndex === 1){
          if (this._cookieService.check('qokd4acpeixRZmLMl2hL0YocMju0xGIveOgT'))
          storageService.encryptAndStore(JSON.stringify(this.complemnetaryInformation),'qokd4acpeixRZmLMl2hL0YocMju0xGIveOgT',this._cookieService) ;
          this.stepIndex = 0 ;
        } else {
          history.pushState(null, '');
        }
      });

      this.orgInfoService.orgInfo$.subscribe((info) => {
        this.orgInfo = info;
        const selectedStepIcon = this.el.nativeElement.querySelector('.mat-step-icon-selected');
        const dynamicColor = this.orgInfo?.organisme?.brand.parcours_souscription.couleurs[0];
       
        if (selectedStepIcon && dynamicColor) {
          selectedStepIcon.setAttribute(
            'style', 
            `background-color: ${dynamicColor} !important;`
          );
        }

        
        this.updateFavicon(this.orgInfo?.organisme.favicon)
   
      }); 
  }

  
  getComplementaryInformation(value :any) : void{
    this.complemnetaryInformation = value.complementaryInfo
    this.isComplemnetaryInformationValid = value.valid;
    this.isComplemnetaryInformationEtatAgeValid = value.validateEtatAge ;

  }

  getQuestions(value :any) : void{
    this.questions = value.questions
    this.isQuestionsValid = value.valid;
    this.isSurfaceKiosqueValide = value.validateSurfaceKiosque ;

  }

  getTarificationRequestValues(value :any) : void{
    let index = null ;
    this.submitTarfication(index);
  }

  getDisableInfoSouscripteurValue(value: any) : void {
    this.disableButtonsDevis = !value.disableInfoSouscripteurInputs ;
    if (value.sendDevis !== null && value.sendDevis !== undefined) {
      this.generateDevis.sendDevis = value.sendDevis ;
    } 
  }

  validateStepPrincipalInformation(index: number) {
    this.informationForm.markAllAsTouched();
    if (this.informationForm.controls.postalCode.value !== ""){
      if (this.informationForm.controls.city.value !== "") {
        if (this.informationForm.controls.taille_habitation.value) {
          if (this.informationForm.controls.taille_habitation.value !== "" && parseFloat(this.informationForm.controls.taille_habitation.value) <= 450 && this.informationForm.controls.taille_habitation.value > 0) {
            if(this.informationForm.valid){
            this._errorDialogService.closeErrorDialog() ;
            this.stepIndex = index;
            storageService.encryptAndStore(this.stepIndex.toString(),'xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD',this._cookieService) ;
            storageService.encryptAndStore(JSON.stringify(this.informationForm.value),'1F3LgNxGKzwVhvRa0wyLidiA3aoudG0',this._cookieService) ;
            // this._cookieService.set('stepIndex', this.stepIndex.toString());
            // this._cookieService.set('principalInformation' , JSON.stringify(this.informationForm.value));
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            }else{
              this._errorDialogService.openErrorDialog("Veuillez remplir les champs requis", 'Fermer',false);
            }
          } else {
            this._errorDialogService.openErrorDialog("Nous sommes désolés, nous ne pouvons pas assurer ce type d'habitation !", 'Fermer',true)
          }

        } else {
          this._errorDialogService.openErrorDialog("Veuillez remplir les champs requis", 'Fermer',false)
        }
      } else {
        this._errorDialogService.openErrorDialog("Nous sommes désolés, nous ne pouvons pas assurer ce type d'habitation !", 'Fermer',true)
      }
    } else {
      this._errorDialogService.openErrorDialog("Veuillez remplir les champs requis", 'Fermer',false)
    }
  }


   validateStepComplemnetaryInformation(index: number) {
    this.isComplemnetaryInformationClicked = true;
    if (this.complemnetaryInformation.sinistres !== true && this.complemnetaryInformation.denivele_terrain !== true && this.complemnetaryInformation.zone_risque !== true) {
      if (this.complemnetaryInformation.resilie  == true && this.complemnetaryInformation.certificat_file == null) {
        this._errorDialogService.openErrorDialog("Le téléversement du certificat de radiation est obligatoire !", 'Fermer',true)
      } else {
      if (this.complemnetaryInformation.etat_bien !== "Vétuste") {
        if (this.isComplemnetaryInformationValid) {
          if (parseInt(this.complemnetaryInformation.annee_construction!.toString()) <= new Date().getFullYear()) {
            if(this.isComplemnetaryInformationEtatAgeValid){
              if (this.complemnetaryInformation.dur !== false) {
                this._errorDialogService.closeErrorDialog() ;
                this.stepIndex = index;
                storageService.encryptAndStore(this.stepIndex.toString(),'xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD',this._cookieService) ;
                storageService.encryptAndStore(JSON.stringify(this.complemnetaryInformation),'qokd4acpeixRZmLMl2hL0YocMju0xGIveOgT',this._cookieService) ;
                // this._cookieService.set('stepIndex', this.stepIndex.toString());
                // this._cookieService.set('complemnetaryInformation' , JSON.stringify(this.complemnetaryInformation));
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });
              } else {
                this._errorDialogService.openErrorDialog("Nous sommes désolés, nous ne pouvons pas assurer ce type d'habitation !", 'Fermer',true)
              }
            }else{
              this._errorDialogService.openErrorDialog("Nous sommes désolés, nous ne pouvons pas assurer ce type d'habitation !", 'Fermer',true)
            }
          } else {
            this._errorDialogService.openErrorDialog("La date de construction ne peut pas être dans le futur", 'Fermer',true);
          }
        } else {
          this._errorDialogService.openErrorDialog("Veuillez remplir les champs requis", 'Fermer',false)
        }
      } else {
        this._errorDialogService.openErrorDialog("Nous sommes désolés, nous ne pouvons pas assurer ce type d'habitation !", 'Fermer',true)
      }
    }

    } else {
      this._errorDialogService.openErrorDialog("Nous sommes désolés, nous ne pouvons pas assurer ce type d'habitation !", 'Fermer',true)
    }
  }


  validateStepQuestions(index: number) {
    this.isQuestionsClicked = true;
    if (this.questions.niveau_protection !== null) {
      if (this.questions.niveau_protection !== 1) {
        if (this.isQuestionsValid){
          if (this.isSurfaceKiosqueValide !== false){
            if (this.questions.Formule !== null) {
              if (this.questions.acceptPub !== null) {
                this.loadingScreenService.show() ;
                this.mapper(index);
              } else {
                this._errorDialogService.openErrorDialog("Veuillez remplir les champs requis", 'Fermer',false)
              }
            } else {
              this._errorDialogService.openErrorDialog("Veuillez remplir les champs requis", 'Fermer',false)
            }

          } else {
            this._errorDialogService.openErrorDialog("Nous sommes désolés, la surface de votre kiosque ne peut pas faire l'objet d'une garantie !", 'Fermer',true);
          }
        } else {
          this._errorDialogService.openErrorDialog("Veuillez remplir les champs requis", 'Fermer',false)
        }
      } else {
        this._errorDialogService.openErrorDialog("Nous sommes désolés, nous ne pouvons pas assurer ce type d'habitation !", 'Fermer',true)
      }
    } else {
      this._errorDialogService.openErrorDialog("Veuillez remplir les champs requis", 'Fermer',false)
    }
  }


  nextStep(index: number): void{
    if(this.stepIndex === 0){
      this.validateStepPrincipalInformation(index) ;
    } else if(this.stepIndex === 1){
      this.validateStepComplemnetaryInformation(index) ;
    } else if(this.stepIndex === 2){
      this.validateStepQuestions(index) ;
    }
  }

  previousStep(index: number): void{
    if(this.stepIndex === 3){
      this.stepIndex = index ;
    } else if(this.stepIndex === 2){
      if (this._cookieService.check('hgI2Zz6HvgNYhMNxNHeptp061gdn4yM1zJC7'))
      storageService.encryptAndStore(JSON.stringify(this.questions),'hgI2Zz6HvgNYhMNxNHeptp061gdn4yM1zJC7',this._cookieService) ;
      this.stepIndex = index ;
    } else if(this.stepIndex === 1){
      if (this._cookieService.check('qokd4acpeixRZmLMl2hL0YocMju0xGIveOgT'))
      storageService.encryptAndStore(JSON.stringify(this.complemnetaryInformation),'qokd4acpeixRZmLMl2hL0YocMju0xGIveOgT',this._cookieService) ;
      this.stepIndex = index ;
    }
  }

  mapper(index:number | null) : void{
      this.tarification.habitation.city = this.informationForm.value.city
      this.tarification.habitation.postalCode = this.informationForm.value.postalCode.toString()
      this.tarification.habitation.taille_habitation = this.informationForm.value.taille_habitation
      this.tarification.contrat = this.informationForm.value.contrat;
      this.tarification.num_contrat = this.informationForm.value.num_contrat;
      this.tarification.habitation.residence = this.informationForm.value.residence
      this.tarification.habitation.typeHabitation = this.informationForm.value.typeHabitation
      this.tarification.habitation.absence_habitation = this.complemnetaryInformation.absence_habitation
      this.tarification.habitation.annee_construction = this.complemnetaryInformation.annee_construction
      this.tarification.habitation.bois = this.complemnetaryInformation.bois
      this.tarification.habitation.denivele_terrain = this.complemnetaryInformation.denivele_terrain
      this.tarification.habitation.dur = this.complemnetaryInformation.dur
      this.tarification.habitation.etat_bien = this.complemnetaryInformation.etat_bien
      this.tarification.habitation.resilie = this.complemnetaryInformation.resilie
      this.tarification.habitation.sinistres = this.complemnetaryInformation.sinistres
      this.tarification.habitation.zone_risque = this.complemnetaryInformation.zone_risque
      this.tarification.habitation.niveau_protection = this.questions.niveau_protection
      this.tarification.habitation.alarme = this.questions.alarme;
      this.tarification.habitation.place_alarme = this.questions.place_alarme
      this.tarification.habitation.terrasse = this.questions.terrasse
      this.tarification.habitation.surface_terrasse = this.questions.surface_terrasse
      this.tarification.habitation.kiosque = this.questions.kiosque
      this.tarification.habitation.surface_kiosque = this.questions.surface_kiosque;
      this.tarification.habitation.veranda = this.questions.veranda
      this.tarification.habitation.location_meuble = this.questions.location_meuble
      this.tarification.habitation.garage = this.questions.garage
      this.tarification.habitation.box = this.questions.box;
      this.tarification.habitation.capital_mobilier = this.questions.capital_mobilier;
      this.tarification.habitation.typeProspect = this.informationForm.value.typeProspect ; 
      this.tarification.Formule = this.questions.Formule;
      this.tarification.dateEffet = this.convertDateFormat(this.complemnetaryInformation.effectiveDate) ;

      if(this.tarification.habitation.surface_terrasse == null){
        delete this.tarification.habitation.surface_terrasse;
      }
      if(this.questions.capital_vol <= 50000){
        delete this.tarification.habitation.alarme;
        delete this.tarification.habitation.place_alarme;
      }

      if (this.tarification.habitation.alarme == true) {
        delete this.tarification.habitation.place_alarme;
      }

      if(this.tarification.habitation.surface_kiosque == null){
        delete this.tarification.habitation.surface_kiosque;
      }
      this.assignCoveragesDependingOnFormula() ;
      this.submitTarfication(index);
  }

  assignCoveragesDependingOnFormula() : void {
    if (this.tarification.Formule === "MRH Basique") {
      this.tarification.opt_valeur_neuf = false ;
      this.tarification.opt_assistance = false ;
      this.tarification.opt_vol = false ;
      this.tarification.opt_pj = false ;
      this.tarification.opt_canalisation_ent = false ;
      this.tarification.opt_debordement = false ;
      this.tarification.opt_bris_glace_mobilier = false ;
      this.tarification.opt_bris_glace_veranda = false ;
      this.tarification.opt_panneaux_photo = false ;
      this.tarification.opt_piscine_spa = false ;
      this.tarification.opt_bien_exterieur = false ;
      this.tarification.opt_extension_chambre = false ;
      this.tarification.opt_extension_location_sais = false ;
      this.tarification.opt_perte_loyer = false ;
      this.tarification.opt_RC_assistance_maternelle = false ;
      this.tarification.opt_RC_aidant = false ;
      this.tarification.opt_kiosque = false ;
      this.tarification.opt_terrasse = false ;
      this.tarification.opt_RC_fete = false ;
      this.tarification.opt_PNO_location_sais = false ;
      this.tarification.opt_contenu_pros = false ;
    } else if (this.tarification.Formule === "MRH Premium") {
      this.tarification.opt_valeur_neuf = true ;
      this.tarification.opt_assistance = true ;
      this.tarification.opt_vol = true ;
      this.tarification.opt_pj = true ;
      this.tarification.opt_canalisation_ent = true ;
      this.tarification.opt_debordement = true ;
      this.tarification.opt_bris_glace_mobilier = true ;
      this.tarification.opt_bris_glace_veranda = true ;
      this.tarification.opt_panneaux_photo = true ;
      this.tarification.opt_piscine_spa = true ;
      this.tarification.opt_bien_exterieur = true ;
      this.tarification.opt_extension_chambre = true ;
      this.tarification.opt_extension_location_sais = true ;
      this.tarification.opt_perte_loyer = true ;
      this.tarification.opt_RC_assistance_maternelle = true ;
      this.tarification.opt_RC_aidant = true ;
      this.tarification.opt_kiosque = true ;
      this.tarification.opt_terrasse = true ;
      this.tarification.opt_RC_fete = true ;
      this.tarification.opt_PNO_location_sais = true ;
      this.tarification.opt_contenu_pros = true ;
    }


    if (this.tarification.habitation.typeProspect == "Propriétaire non occupant") {
      this.tarification.opt_pj = false ;
      this.tarification.opt_assistance = false ;
      this.tarification.opt_perte_loyer = true ;
    } else {
      if (this.tarification.habitation.typeProspect == "Propriétaire occupant") {
        this.tarification.opt_perte_loyer = true ;
      } else {
        this.tarification.opt_perte_loyer = false ;
      }
      this.tarification.opt_assistance = true ;
    }

    if (this.tarification.habitation.typeHabitation == "Appartement") {
      this.tarification.opt_canalisation_ent = false ;
      this.tarification.opt_debordement = false ;
      this.tarification.opt_bris_glace_veranda = false ;
      this.tarification.opt_panneaux_photo = false ;
      this.tarification.opt_piscine_spa = false ;
      this.tarification.opt_bien_exterieur = false ;
      this.tarification.opt_kiosque = false ;
      this.tarification.opt_terrasse = false ;
    } 
  }

  submitTarfication(index:number | null) : void{
    this.tarificationLoading = true;
    this.generateDevis.sendDevis = false ;
    this.infoSouscriptionResponse = new InfoSouscriptionResponse() ;
    this._pagesService.tarification(this.tarification)
    .subscribe((response : TarificationResponse)=>{
      this.tarificationResponse = response ;
      this.generateDevis.tarifRef = response.references?.tarifRef;
      if (this._cookieService.check('N1IBksq1v8UJNPpFFijNo5lX402q959izA60')) {
        storageService.encryptAndStore(JSON.stringify(this.tarification),'N1IBksq1v8UJNPpFFijNo5lX402q959izA60',this._cookieService) ;
      }

      if (this._cookieService.check('DBXdywlRerb8nkfptnAc0Y0ugP0mzn')) {
        storageService.encryptAndStore(JSON.stringify(this.tarificationResponse),'DBXdywlRerb8nkfptnAc0Y0ugP0mzn',this._cookieService) ;
      }

      if (this._cookieService.check('VIidV08pjAT82HXeLETH7s7eYMccTK8')) {
        this._cookieService.delete("VIidV08pjAT82HXeLETH7s7eYMccTK8")
      }

      this.tarificationLoading = false;
      this.loadingScreenService.hide() ;
      if (index !== null)  {
        this._errorDialogService.closeErrorDialog() ;
        this.stepIndex = index;
        storageService.encryptAndStore(this.stepIndex.toString(),'xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD',this._cookieService) ;
        storageService.encryptAndStore(JSON.stringify(this.questions),'hgI2Zz6HvgNYhMNxNHeptp061gdn4yM1zJC7',this._cookieService) ;

        storageService.encryptAndStore(JSON.stringify(this.tarification),'N1IBksq1v8UJNPpFFijNo5lX402q959izA60',this._cookieService) ;
        storageService.encryptAndStore(JSON.stringify(this.tarificationResponse),'DBXdywlRerb8nkfptnAc0Y0ugP0mzn',this._cookieService) ;
        // this._cookieService.set('stepIndex', this.stepIndex.toString());
        // this._cookieService.set('questions' , JSON.stringify(this.questions));
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }

    },(error: HttpErrorResponse)=>{
      this.tarificationLoading = false;
      this.loadingScreenService.hide() ;
      this._errorDialogService.openErrorDialog(error.error.message, 'Fermer', true);
    })
  }

  validateSubscriberInfos () : boolean {
    if ((this.generateDevis.client.firstName != null && this.generateDevis.client.firstName.trim() != "")
    && (this.generateDevis.client.lastName != null && this.generateDevis.client.lastName.trim() != "")
    && (this.generateDevis.client.email != null && this.generateDevis.client.email.trim() != "")
    && (this.generateDevis.client.mobile != null && this.generateDevis.client.mobile.trim() != "")
    && (this.generateDevis.client.address.city != null && this.generateDevis.client.address.city.trim() != "")
    && (this.generateDevis.client.address.streetName != null && this.generateDevis.client.address.streetName.trim() != "")
    && (this.generateDevis.client.address.postalCode != null && this.generateDevis.client.address.postalCode.trim() != "")
    ) {
      return true ;
    } else {
      return false ;
    }
  }

  onGenerateDevis(sendDevis: boolean) : void{ 
    if (this.validateSubscriberInfos()) {
      this.disableInfoSouscripteurInputs = true ;
      if (sendDevis == true)
    this.devisSendingLoading = true ;
    else
    this.devisDownloadLoading = true;


    if(this.generateDevis.opportuniteRef == null)
    delete this.generateDevis.opportuniteRef;

    if(this.generateDevis.client.AccountHolder == null)
    delete this.generateDevis.client.AccountHolder;

    if(this.generateDevis.client.birthDate == null)
    delete this.generateDevis.client.birthDate;

    if(this.generateDevis.client.address.country == null)
    delete this.generateDevis.client.address.country;

    if(this.generateDevis.client.address.description == null)
    delete this.generateDevis.client.address.description;

    if(this.generateDevis.client.address.streetNumber == null)
    delete this.generateDevis.client.address.streetNumber;

    delete this.generateDevis.sendDevis

    this.generateDevis.tarifRef = this.tarificationResponse.references?.tarifRef ;
    this.getInfoSouscription.tarifRef = this.generateDevis.tarifRef! ;
    this.generateDevis.client.typeProspect = this.informationForm.value.typeProspect;
    this._pagesService.generateDevis(this.generateDevis, sendDevis, this.questions.acceptPub)
    .subscribe((response: GenerateDevisResponse)=>{
      this.generateDevis.opportuniteRef = response.opportuniteRef;
      this.generateDevis.sendDevis = sendDevis;
      if (sendDevis == false)
      this.downloadDevis(response.devis_base64);
      storageService.encryptAndStore(JSON.stringify(this.generateDevis),'s6P4XRSAjMhiSvGrvybGy0BSwrXhHdSySoJR',this._cookieService) ;
      if (sendDevis == true)
      this.devisSendingLoading = false ;
      else
      this.devisDownloadLoading = false;

      this._pagesService.upload_certif_radiation(this.complemnetaryInformation.certificat_file!,this.generateDevis.opportuniteRef!).subscribe(() => {
      })

    },(error: HttpErrorResponse)=>{
      if (sendDevis == true)
      this.devisSendingLoading = false ;
      else
      this.devisDownloadLoading = false;
      this.disableInfoSouscripteurInputs = false ;

      this._errorDialogService.openErrorDialog(error.error.message, 'Fermer', true);
    })
    } else{
      this._errorDialogService.openErrorDialog("Veuillez remplir les informations de souscripteur", 'Fermer', false);
    }


  }

  downloadDevis(devisBase64: string | null): void{
    if(devisBase64){
      var temp = 'data:application/pdf;base64,' + encodeURIComponent(devisBase64);
      var download = document.createElement('a');
      download.href = temp;

      const dateNow: string = formatDate(new Date(), "yyyy-MM-dd HH:mm", "en");

      download.download ='devis_'+dateNow+'.pdf';

      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
    }
  }

  subscribe(): void {
    if (this._cookieService.check('VIidV08pjAT82HXeLETH7s7eYMccTK8')) {
      this.infoSouscriptionResponse = JSON.parse(storageService.decrypt(this._cookieService.get('VIidV08pjAT82HXeLETH7s7eYMccTK8')));
      this.stepIndex = 4 ;
      storageService.encryptAndStore(this.stepIndex.toString(),'xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD',this._cookieService) ;

      this._routes.navigate(['/souscription']) ;
    } else {
      this._pagesService.getSubscriptionInfos(this.getInfoSouscription).subscribe((response: InfoSouscriptionResponse)=>{
        this.infoSouscriptionResponse = response ;
        this.stepIndex = 4 ;
        storageService.encryptAndStore(this.stepIndex.toString(),'xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD',this._cookieService) ;
        storageService.encryptAndStore(JSON.stringify(this.infoSouscriptionResponse),'VIidV08pjAT82HXeLETH7s7eYMccTK8',this._cookieService) ;

        this._routes.navigate(['/souscription']) ;
       },(error: HttpErrorResponse)=>{
        this._errorDialogService.openErrorDialog(error.error.message, 'Fermer', true);
      });
    }
  }

  loadDateFromCookies() : void {

    //stepIndex
    // if(this._cookieService.check('stepIndex')){
    //   this.stepIndex = parseInt(this._cookieService.get('stepIndex'));
    // }
    if(this._cookieService.check('xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD')){
      this.stepIndex = parseInt(storageService.decrypt(this._cookieService.get('xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD')));

      //principalInformation
    if(this._cookieService.check('1F3LgNxGKzwVhvRa0wyLidiA3aoudG0')){
      this.informationForm.patchValue(JSON.parse(storageService.decrypt(this._cookieService.get('1F3LgNxGKzwVhvRa0wyLidiA3aoudG0'))));
    }

    // if(this._cookieService.check('principalInformation')){
    //   this.informationForm.patchValue(JSON.parse(this._cookieService.get('principalInformation')));
    // }


    //complemnetaryInformation
    if(this._cookieService.check('qokd4acpeixRZmLMl2hL0YocMju0xGIveOgT')){
      this.complemnetaryInformation = JSON.parse(storageService.decrypt(this._cookieService.get('qokd4acpeixRZmLMl2hL0YocMju0xGIveOgT')));
    }
    // if(this._cookieService.check('complemnetaryInformation')){
    //   this.complemnetaryInformation = JSON.parse(this._cookieService.get('complemnetaryInformation'));
    // }

    //questions
    if(this._cookieService.check('hgI2Zz6HvgNYhMNxNHeptp061gdn4yM1zJC7')){
      this.questions = JSON.parse(storageService.decrypt(this._cookieService.get('hgI2Zz6HvgNYhMNxNHeptp061gdn4yM1zJC7')));
    }
    // if(this._cookieService.check('questions')){
    //   this.questions = JSON.parse(this._cookieService.get('questions'));
    // }

    // TarificationRequest
    if (this._cookieService.check('N1IBksq1v8UJNPpFFijNo5lX402q959izA60')) {
      this.tarification = JSON.parse(storageService.decrypt(this._cookieService.get('N1IBksq1v8UJNPpFFijNo5lX402q959izA60')));
    }

    // TarificationResponse
    if (this._cookieService.check('DBXdywlRerb8nkfptnAc0Y0ugP0mzn')) {
      this.tarificationResponse = JSON.parse(storageService.decrypt(this._cookieService.get('DBXdywlRerb8nkfptnAc0Y0ugP0mzn')));
    }

    //generateDevisBodyRequest
    if (this._cookieService.check('s6P4XRSAjMhiSvGrvybGy0BSwrXhHdSySoJR')) {
      this.generateDevis = JSON.parse(storageService.decrypt(this._cookieService.get('s6P4XRSAjMhiSvGrvybGy0BSwrXhHdSySoJR')));
    }
    }
  }

  convertDateFormat(inputDate: string): string {
    const [day, month, year] = inputDate.split('/');
    const dateObject = new Date(`${year}-${month}-${day}`);
    const convertedDate = dateObject.toISOString().split('T')[0];
  
    return convertedDate;
  }
}
