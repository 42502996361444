import { Component, ElementRef, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as storageService from './pages/pages/_utils.ts/dataStorage';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { OrgInfoService } from './services/organisme/org-info.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      state('out', style({
        transform: 'translateX(-100%)',
        opacity: 0 
      })),
      transition('in => out', animate('300ms ease-out')),
      transition('out => in', animate('300ms ease-in'))
    ]) ,

    trigger('buttonSlide', [
      state('in', style({
        transform: 'translateX(0)',
      })),
      state('out', style({
        transform: 'translateX(-430%)',
      })),
      transition('in => out', animate('300ms ease-out')),
      transition('out => in', animate('300ms ease-in'))
    ]) , 

    trigger('meteoSlideWidth', [
      state('in', style({ width: '*' })),
      state('out', style({ width: '42px' })),
      transition('out => in', animate('0.3s ease-in-out')),
    ]),
  ]
})
export class AppComponent {
  title = 'parcours-mrh-b2b';
  meteoFrameStatus = 'in';
  buttonSlide = "in";
  meteoSlideWidth = "in" ; 
  orgInfo: any;
  

  constructor(private _cookieService: CookieService,private _router: Router ,  private orgInfoService: OrgInfoService 
    ,private el: ElementRef, private renderer: Renderer2) {}



  ngOnInit(): void {
   
    const hoverColor = this.orgInfo?.organisme?.brand.parcours_souscription.couleurs[0];



    this.orgInfoService.orgInfo$.subscribe((info) => {
      this.orgInfo = info;
      const hoverColor = this.orgInfo?.organisme?.brand.parcours_souscription.couleurs[0];
      if (hoverColor) {
        this.renderer.setStyle(this.el.nativeElement, '--hover-color', hoverColor);
      }
    console.log(info)
    });
  }


  clear() : void {
    let apiKey = "";
    if(this._cookieService.check("6GrL131shBI6OCImJkM1v8UkVeWeXYlKdD3Khp8")) {
      apiKey = storageService.decrypt(this._cookieService.get('6GrL131shBI6OCImJkM1v8UkVeWeXYlKdD3Khp8'));
      this._cookieService.deleteAll();
      location.reload() ; 

      this._router.navigate(["/"], { queryParams: { partnership_id: apiKey} });
    }
      
  }

  toggleMeteo() {
    this.meteoFrameStatus = this.meteoFrameStatus === 'out' ? 'in' : 'out';
    this.buttonSlide = this.meteoFrameStatus ; 
    this.meteoSlideWidth = this.buttonSlide ; 
  }


  
}
