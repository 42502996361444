<app-loading-screen *ngIf="loadingScreenService.isLoading$ | async"></app-loading-screen>

<div id="b2c">
    <!-- <div class="header">
        <img src="/assets/logos/logo.png" alt="">
    </div> -->

    <div id="confirm-paiement">

        <div class="step-content" id="scrollbar">

            <div class="header" [ngStyle]="{ 'background-color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[5] }">
                <!-- <span class="step-number">5</span> -->
                <h1 [ngStyle]="{ 'color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] }">Confirmation Paiement</h1>
            </div>
            <div class="m-5">
                <div class="fixed-cotisation-show">
                    <div class="my-4 ">
                        <div class="d-flex flex-row justify-content-center align-items-baseline ml-3">
                            <h3 class="MoneyBig" [ngStyle]="{ 'color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] }"  *ngIf="typeoffre && infoSouscriptionResponse.recapitulative">{{infoSouscriptionResponse.recapitulative.montant_annuel_hors_gareat | currency:currency:'symbol':formatCurrency:'fr-FR'}}</h3>
                            <h3 class="MoneyBig" [ngStyle]="{ 'color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] }"  *ngIf="!typeoffre && infoSouscriptionResponse.recapitulative">{{infoSouscriptionResponse.recapitulative.montant_mensuel | currency:currency:'symbol':formatCurrency:'fr-FR'}}</h3>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center mt-1">
                            <div class="d-flex flex-column justify-content-center align-items-center">
                                <h3 class="title mb-0" [ngClass]="{h3red: !typeoffre}" [ngStyle]="{ 'color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] }">Offre Mensuelle</h3>
                            </div>
                            <div class="d-flex justify-content-center align-items-center mx-5">
                                <span class=" slidetoggle d-inline-block" style="scale:2.3;">
                                    <div class="container-switch">
                                        <label class="switch space-switch" style="height:50px;"><input type="checkbox" [(ngModel)]="typeoffre"
                                              ><span class="slider round"></span>
                                </label>
                            </div>
                            </span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center">
                            <h3 class="title mb-0" [ngClass]="{h3red: typeoffre}" [ngStyle]="{ 'color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] }">Offre Annuelle</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="togglecontainer mb-5">
                <div id="carte" class="d-flex flex-row flex-wrap justify-content-center mb-4">

                    <div class="col-lg-4 mt-4 d-flex justify-content-center align-items-baseline">
                        <div class="card-content cursor-default">
                            <img class="mt-2 mb-2" src="assets/svg-b2c/moneyadd.svg" alt="Icon">
                            <span class="title mb-1">Frais supplémentaires</span>
                            <ng-container *ngFor="let frais of infoSouscriptionResponse.recapitulative.frais_supp | keyvalue">
                                <span *ngIf="frais.key !== 'Total' && frais.value.amortized !== false" class="description mt-2 flex-grow-1">{{ frais.value.label }} {{ (typeoffre ? frais.value.val : frais.value.amortized ? frais.value.val_mensuelle : frais.value.val) | currency:currency:'symbol':formatCurrency:'fr-FR'}}/{{ typeoffre ? frais.value.amortized ? 'an' : '1ère échéance' : frais.value.amortized ? 'mois' : '1ère échéance' }}</span>
                            </ng-container>

                            <span class="title mt-4 mb-1">Exigible au 1er paiement</span>
                            <ng-container *ngFor="let frais of infoSouscriptionResponse.recapitulative.frais_supp | keyvalue">
                                <span *ngIf="frais.key !== 'Total' && frais.value.amortized === false" class="description mt-2 flex-grow-1">{{ frais.value.label }} {{ (typeoffre ? frais.value.val : frais.value.amortized ? frais.value.val_mensuelle : frais.value.val) | currency:currency:'symbol':formatCurrency:'fr-FR'}}</span>
                            </ng-container>
                            <span class="description mt-2 flex-grow-1">Taxe attentats GAREAT 5,9 €  </span>
                        </div>
                    </div>

                    
                    <div class="col-lg-4 mt-4">
                        <div class="card-content cursor-default">
                            <img class="mt-2 mb-2" src="assets/svg-b2c/moneytick.svg" alt="Icon">
                            <span class="title mb-1">{{ typeoffre ? 'Montant de la cotisation annuelle' : 'Montant de la première cotisation' }}</span>
                            <span class="description mt-2 flex-grow-1">{{(typeoffre ? infoSouscriptionResponse.recapitulative.montant_annuel_frais  : infoSouscriptionResponse.recapitulative.premiere_echeance) | currency:currency:'symbol':formatCurrency:'fr-FR' }}</span>
                            <span class="title mb-1" *ngIf="!typeoffre">Montant des prochaines cotisations</span>
                            <span class="title mb-1" *ngIf="!typeoffre">{{infoSouscriptionResponse.recapitulative.montant_mensuel_frais | currency:currency:'symbol':formatCurrency:'fr-FR'}}</span>
                        </div>
                    </div>

                    <div class="col-lg-4 mt-4">
                        <div class="card-content cursor-default">
                            <img class="mt-2 mb-2" src="assets/svg-b2c/calendartick.svg" alt="Icon">
                            <span class="title mb-1">Délai du contrat</span>
                            <span class="description mt-2 flex-grow-1">{{infoSouscriptionResponse.recapitulative.date_debut}} au {{infoSouscriptionResponse.recapitulative.date_fin}}</span>
                        </div>
                    </div>
                </div>

            </div>


            <!-- <div class="d-flex flex-row justify-content-center align-items-center mt-3" *ngIf="!typeoffre">
                <div class="container">
                    <div class="row justify-content-center align-items-center garantiesOblig">
                        <div class="col-lg-10 col-md-12  align-items-center justify-content-center">
                            <h3 class="titleblue mt-3">
                                <mat-icon class="icon">error_outline</mat-icon> La première mensualité s'élève à {{infoSouscriptionResponse.recapitulative.premiere_echeance | currency:currency:'symbol':formatCurrency:'fr-FR'}} , dont le(s) frais mentionné(s) ci-dessus (/1ère
                                échéance) et taxe attentats GAREAT de 5,9 €
                            </h3>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="d-flex flex-row justify-content-center align-items-baseline mt-5">
                <h2 class="title"> <span class="text-danger">*</span>Veuillez choisir le mode de paiement préféré</h2>
            </div>

            <div id="carte" class="d-flex flex-row flex-wrap justify-content-around container">

                <div class="col-lg-4 mt-4 togglecontainer">
                    <div class="card-content d-flex flex-row justify-content-center align-items-center" [ngStyle]="{'border': signRequest.infoBancaire.mode_paiement === 'iban' ? '1px solid' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}" (click)="signRequest.infoBancaire.mode_paiement = 'iban' ; selectPeriodicity(null) ; selectJrPrelevement(null)">
                        <span class="title" [ngStyle]="{'color': signRequest.infoBancaire.mode_paiement == 'iban' ? '#292371' : '#727272'}">Mandat SEPA</span>
                        <div class="icon-container">
                            <span class="min-checkbox-selected-hide" *ngIf="signRequest.infoBancaire.mode_paiement === 'iban'" [ngClass]="{'min-checkbox-selected': signRequest.infoBancaire.mode_paiement === 'iban'}">
                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.00033 0.700504C7.22062 0.698505 5.48092 1.22512 4.01116 2.21437C2.5314 3.20263 1.38153 4.60858 0.701647 6.25234C-0.00823594 7.8881 -0.188206 9.70767 0.201729 11.4484C0.531675 13.186 1.36156 14.7879 2.60136 16.0529C3.87115 17.294 5.46086 18.1483 7.20058 18.5141C8.51036 18.7849 9.86022 18.7599 11.16 18.4411C12.4598 18.1234 13.6695 17.5198 14.6993 16.6744C15.7392 15.8291 16.5691 14.7639 17.139 13.5568C17.7089 12.3487 18.0088 11.0297 17.9988 9.6937C18.0188 8.5096 17.7789 7.33552 17.299 6.25234C16.8691 5.16016 16.2292 4.16787 15.3993 3.3345C14.5294 2.51012 13.5096 1.84963 12.3998 1.38498C11.33 0.923333 10.1701 0.690511 9.00033 0.700504ZM13.5995 7.20761L7.80057 13.0142C7.74058 13.0861 7.66051 13.1451 7.58052 13.1861C7.49053 13.227 7.40064 13.249 7.30066 13.252C7.20067 13.249 7.11066 13.226 7.02067 13.184C6.94069 13.1411 6.86073 13.0811 6.80074 13.0062L4.40111 10.584C4.40111 10.568 4.40113 10.5491 4.30115 10.5281V10.3942C4.40113 10.3732 4.40111 10.3542 4.40111 10.3382L5.20091 9.57081V9.53281C5.20091 9.52382 5.30099 9.51885 5.30099 9.51885V9.53281C5.40097 9.54081 5.40095 9.55382 5.40095 9.57081L7.30066 11.4903L12.5997 6.17738C12.5997 6.16039 12.5997 6.1474 12.6997 6.13841V6.12542C12.6997 6.12542 12.7998 6.12538 12.7998 6.13737V6.18134L13.5995 6.96377V7.0197C13.6995 7.04068 13.6995 7.06368 13.6995 7.08667C13.6995 7.10965 13.6995 7.13262 13.5995 7.15461V7.20761Z" fill="#E30613"/>
                                    </svg>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 mt-4 togglecontainer"> 
                    <div class="card-content d-flex flex-row justify-content-center align-items-center" [ngStyle]="{'border': signRequest.infoBancaire.mode_paiement === 'card' ? '1px solid' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}" (click)="signRequest.infoBancaire.mode_paiement = 'card' ; selectPeriodicity(null) ; selectJrPrelevement(0)">
                        <span class="title" [ngStyle]="{'color': signRequest.infoBancaire.mode_paiement == 'card' ? '#292371' : '#727272'}">Carte Bancaire</span>
                        <div class="icon-container">
                            <span class="min-checkbox-selected-hide" *ngIf="signRequest.infoBancaire.mode_paiement === 'card'" [ngClass]="{'min-checkbox-selected': signRequest.infoBancaire.mode_paiement === 'card'}">
                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.00033 0.700504C7.22062 0.698505 5.48092 1.22512 4.01116 2.21437C2.5314 3.20263 1.38153 4.60858 0.701647 6.25234C-0.00823594 7.8881 -0.188206 9.70767 0.201729 11.4484C0.531675 13.186 1.36156 14.7879 2.60136 16.0529C3.87115 17.294 5.46086 18.1483 7.20058 18.5141C8.51036 18.7849 9.86022 18.7599 11.16 18.4411C12.4598 18.1234 13.6695 17.5198 14.6993 16.6744C15.7392 15.8291 16.5691 14.7639 17.139 13.5568C17.7089 12.3487 18.0088 11.0297 17.9988 9.6937C18.0188 8.5096 17.7789 7.33552 17.299 6.25234C16.8691 5.16016 16.2292 4.16787 15.3993 3.3345C14.5294 2.51012 13.5096 1.84963 12.3998 1.38498C11.33 0.923333 10.1701 0.690511 9.00033 0.700504ZM13.5995 7.20761L7.80057 13.0142C7.74058 13.0861 7.66051 13.1451 7.58052 13.1861C7.49053 13.227 7.40064 13.249 7.30066 13.252C7.20067 13.249 7.11066 13.226 7.02067 13.184C6.94069 13.1411 6.86073 13.0811 6.80074 13.0062L4.40111 10.584C4.40111 10.568 4.40113 10.5491 4.30115 10.5281V10.3942C4.40113 10.3732 4.40111 10.3542 4.40111 10.3382L5.20091 9.57081V9.53281C5.20091 9.52382 5.30099 9.51885 5.30099 9.51885V9.53281C5.40097 9.54081 5.40095 9.55382 5.40095 9.57081L7.30066 11.4903L12.5997 6.17738C12.5997 6.16039 12.5997 6.1474 12.6997 6.13841V6.12542C12.6997 6.12542 12.7998 6.12538 12.7998 6.13737V6.18134L13.5995 6.96377V7.0197C13.6995 7.04068 13.6995 7.06368 13.6995 7.08667C13.6995 7.10965 13.6995 7.13262 13.5995 7.15461V7.20761Z" fill="#E30613"/>
                                    </svg>
                            </span>
                        </div>
                    </div>
                </div>


            </div>



            <div class="d-flex flex-row justify-content-center align-items-center mt-3" *ngIf="signRequest.infoBancaire.mode_paiement === 'card' || signRequest.infoBancaire.mode_paiement === 'iban'">
                <div class="container">
                    <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                        <div class="col-lg-10 col-md-12 ">
                            <div>
                                <h3 class="titlebluebold"><span class="text-danger">* </span>Fractionnement</h3>
                            </div>
                            <div id="carte" class="d-flex flex-row flex-wrap justify-content-center mb-4">
                                <div class="col-lg-4 mt-4 min-selection-box">
                                    <div class="card-content  min-checkbox-content d-flex flex-row justify-content-center align-items-center" [ngStyle]="{'border': signRequest.infoBancaire.periodicite == 12 ? '1px solid' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}" (click)="signRequest.infoBancaire.mode_paiement === 'iban' ? selectPeriodicity(12) : null">
                                        <span class="titleblue">Mensuelle</span>
                                        <span class="min-checkbox-selected-hide " [ngClass]="{'min-checkbox-selected':signRequest.infoBancaire.periodicite == 12}">
                                            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M9.00033 0.700504C7.22062 0.698505 5.48092 1.22512 4.01116 2.21437C2.5314 3.20263 1.38153 4.60858 0.701647 6.25234C-0.00823594 7.8881 -0.188206 9.70767 0.201729 11.4484C0.531675 13.186 1.36156 14.7879 2.60136 16.0529C3.87115 17.294 5.46086 18.1483 7.20058 18.5141C8.51036 18.7849 9.86022 18.7599 11.16 18.4411C12.4598 18.1234 13.6695 17.5198 14.6993 16.6744C15.7392 15.8291 16.5691 14.7639 17.139 13.5568C17.7089 12.3487 18.0088 11.0297 17.9988 9.6937C18.0188 8.5096 17.7789 7.33552 17.299 6.25234C16.8691 5.16016 16.2292 4.16787 15.3993 3.3345C14.5294 2.51012 13.5096 1.84963 12.3998 1.38498C11.33 0.923333 10.1701 0.690511 9.00033 0.700504ZM13.5995 7.20761L7.80057 13.0142C7.74058 13.0861 7.66051 13.1451 7.58052 13.1861C7.49053 13.227 7.40064 13.249 7.30066 13.252C7.20067 13.249 7.11066 13.226 7.02067 13.184C6.94069 13.1411 6.86073 13.0811 6.80074 13.0062L4.40111 10.584C4.40111 10.568 4.40113 10.5491 4.30115 10.5281V10.3942C4.40113 10.3732 4.40111 10.3542 4.40111 10.3382L5.20091 9.57081V9.53281C5.20091 9.52382 5.30099 9.51885 5.30099 9.51885V9.53281C5.40097 9.54081 5.40095 9.55382 5.40095 9.57081L7.30066 11.4903L12.5997 6.17738C12.5997 6.16039 12.5997 6.1474 12.6997 6.13841V6.12542C12.6997 6.12542 12.7998 6.12538 12.7998 6.13737V6.18134L13.5995 6.96377V7.0197C13.6995 7.04068 13.6995 7.06368 13.6995 7.08667C13.6995 7.10965 13.6995 7.13262 13.5995 7.15461V7.20761Z" fill="#E30613"/>
                                              </svg>
                                          </span>
                                    </div>
                                </div>

                                <div class="col-lg-4 mt-4 d-flex justify-content-center align-items-baseline min-selection-box">
                                    <div class=" card-content  min-checkbox-content d-flex flex-row justify-content-center align-items-center" [ngStyle]="{'border': signRequest.infoBancaire.periodicite ==  1 ? '1px solid' + orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] : 'none'}" (click)="signRequest.infoBancaire.mode_paiement === 'card' ? selectPeriodicity(1) : null">
                                        <span class="titleblue">Annuelle</span>
                                        <span class="min-checkbox-selected-hide " [ngClass]="{'min-checkbox-selected':signRequest.infoBancaire.periodicite == 1}">
                                            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M9.00033 0.700504C7.22062 0.698505 5.48092 1.22512 4.01116 2.21437C2.5314 3.20263 1.38153 4.60858 0.701647 6.25234C-0.00823594 7.8881 -0.188206 9.70767 0.201729 11.4484C0.531675 13.186 1.36156 14.7879 2.60136 16.0529C3.87115 17.294 5.46086 18.1483 7.20058 18.5141C8.51036 18.7849 9.86022 18.7599 11.16 18.4411C12.4598 18.1234 13.6695 17.5198 14.6993 16.6744C15.7392 15.8291 16.5691 14.7639 17.139 13.5568C17.7089 12.3487 18.0088 11.0297 17.9988 9.6937C18.0188 8.5096 17.7789 7.33552 17.299 6.25234C16.8691 5.16016 16.2292 4.16787 15.3993 3.3345C14.5294 2.51012 13.5096 1.84963 12.3998 1.38498C11.33 0.923333 10.1701 0.690511 9.00033 0.700504ZM13.5995 7.20761L7.80057 13.0142C7.74058 13.0861 7.66051 13.1451 7.58052 13.1861C7.49053 13.227 7.40064 13.249 7.30066 13.252C7.20067 13.249 7.11066 13.226 7.02067 13.184C6.94069 13.1411 6.86073 13.0811 6.80074 13.0062L4.40111 10.584C4.40111 10.568 4.40113 10.5491 4.30115 10.5281V10.3942C4.40113 10.3732 4.40111 10.3542 4.40111 10.3382L5.20091 9.57081V9.53281C5.20091 9.52382 5.30099 9.51885 5.30099 9.51885V9.53281C5.40097 9.54081 5.40095 9.55382 5.40095 9.57081L7.30066 11.4903L12.5997 6.17738C12.5997 6.16039 12.5997 6.1474 12.6997 6.13841V6.12542C12.6997 6.12542 12.7998 6.12538 12.7998 6.13737V6.18134L13.5995 6.96377V7.0197C13.6995 7.04068 13.6995 7.06368 13.6995 7.08667C13.6995 7.10965 13.6995 7.13262 13.5995 7.15461V7.20761Z" fill="#E30613"/>
                                              </svg>
                                          </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="d-flex flex-row justify-content-center align-items-center mt-3" *ngIf="signRequest.infoBancaire.mode_paiement === 'iban'">
                <div class="container">
                    <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                        <div class="col-lg-10 col-md-12 ">
                            <div>
                                <h3 class="titlebluebold"><span class="text-danger">* </span>Jour de prélèvement</h3>
                            </div>
                            <div id="carte" class="d-flex flex-row flex-wrap justify-content-center mb-4">
                                <div class="col-lg-4 mt-4 min-selection-box">
                                    <div class="card-content d-flex flex-row justify-content-center align-items-center" [ngStyle]="{'border': signRequest.infoBancaire.jourPrelevement === 5 ? '1px solid #E30613' : 'none'}" (click)="signRequest.infoBancaire.jourPrelevement = 5">
                                        <span class="title">le 5 du mois</span>
                                        <div class="icon-container">
                                            <span class="min-checkbox-selected-hide" *ngIf="signRequest.infoBancaire.jourPrelevement === 5" [ngClass]="{'min-checkbox-selected': signRequest.infoBancaire.jourPrelevement === 5}">
                                                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.00033 0.700504C7.22062 0.698505 5.48092 1.22512 4.01116 2.21437C2.5314 3.20263 1.38153 4.60858 0.701647 6.25234C-0.00823594 7.8881 -0.188206 9.70767 0.201729 11.4484C0.531675 13.186 1.36156 14.7879 2.60136 16.0529C3.87115 17.294 5.46086 18.1483 7.20058 18.5141C8.51036 18.7849 9.86022 18.7599 11.16 18.4411C12.4598 18.1234 13.6695 17.5198 14.6993 16.6744C15.7392 15.8291 16.5691 14.7639 17.139 13.5568C17.7089 12.3487 18.0088 11.0297 17.9988 9.6937C18.0188 8.5096 17.7789 7.33552 17.299 6.25234C16.8691 5.16016 16.2292 4.16787 15.3993 3.3345C14.5294 2.51012 13.5096 1.84963 12.3998 1.38498C11.33 0.923333 10.1701 0.690511 9.00033 0.700504ZM13.5995 7.20761L7.80057 13.0142C7.74058 13.0861 7.66051 13.1451 7.58052 13.1861C7.49053 13.227 7.40064 13.249 7.30066 13.252C7.20067 13.249 7.11066 13.226 7.02067 13.184C6.94069 13.1411 6.86073 13.0811 6.80074 13.0062L4.40111 10.584C4.40111 10.568 4.40113 10.5491 4.30115 10.5281V10.3942C4.40113 10.3732 4.40111 10.3542 4.40111 10.3382L5.20091 9.57081V9.53281C5.20091 9.52382 5.30099 9.51885 5.30099 9.51885V9.53281C5.40097 9.54081 5.40095 9.55382 5.40095 9.57081L7.30066 11.4903L12.5997 6.17738C12.5997 6.16039 12.5997 6.1474 12.6997 6.13841V6.12542C12.6997 6.12542 12.7998 6.12538 12.7998 6.13737V6.18134L13.5995 6.96377V7.0197C13.6995 7.04068 13.6995 7.06368 13.6995 7.08667C13.6995 7.10965 13.6995 7.13262 13.5995 7.15461V7.20761Z" fill="#E30613"/>
                                                        </svg>
                                                </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 mt-4 d-flex justify-content-center align-items-baseline min-selection-box">


                                    <div class="card-content d-flex flex-row justify-content-center align-items-center" [ngStyle]="{'border': signRequest.infoBancaire.jourPrelevement === 10 ? '1px solid #E30613' : 'none'}" (click)="signRequest.infoBancaire.jourPrelevement = 10">
                                        <span class="title">le 10 du mois</span>
                                        <div class="icon-container">
                                            <span class="min-checkbox-selected-hide" *ngIf="signRequest.infoBancaire.jourPrelevement === 10" [ngClass]="{'min-checkbox-selected': signRequest.infoBancaire.jourPrelevement === 10}">
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.00033 0.700504C7.22062 0.698505 5.48092 1.22512 4.01116 2.21437C2.5314 3.20263 1.38153 4.60858 0.701647 6.25234C-0.00823594 7.8881 -0.188206 9.70767 0.201729 11.4484C0.531675 13.186 1.36156 14.7879 2.60136 16.0529C3.87115 17.294 5.46086 18.1483 7.20058 18.5141C8.51036 18.7849 9.86022 18.7599 11.16 18.4411C12.4598 18.1234 13.6695 17.5198 14.6993 16.6744C15.7392 15.8291 16.5691 14.7639 17.139 13.5568C17.7089 12.3487 18.0088 11.0297 17.9988 9.6937C18.0188 8.5096 17.7789 7.33552 17.299 6.25234C16.8691 5.16016 16.2292 4.16787 15.3993 3.3345C14.5294 2.51012 13.5096 1.84963 12.3998 1.38498C11.33 0.923333 10.1701 0.690511 9.00033 0.700504ZM13.5995 7.20761L7.80057 13.0142C7.74058 13.0861 7.66051 13.1451 7.58052 13.1861C7.49053 13.227 7.40064 13.249 7.30066 13.252C7.20067 13.249 7.11066 13.226 7.02067 13.184C6.94069 13.1411 6.86073 13.0811 6.80074 13.0062L4.40111 10.584C4.40111 10.568 4.40113 10.5491 4.30115 10.5281V10.3942C4.40113 10.3732 4.40111 10.3542 4.40111 10.3382L5.20091 9.57081V9.53281C5.20091 9.52382 5.30099 9.51885 5.30099 9.51885V9.53281C5.40097 9.54081 5.40095 9.55382 5.40095 9.57081L7.30066 11.4903L12.5997 6.17738C12.5997 6.16039 12.5997 6.1474 12.6997 6.13841V6.12542C12.6997 6.12542 12.7998 6.12538 12.7998 6.13737V6.18134L13.5995 6.96377V7.0197C13.6995 7.04068 13.6995 7.06368 13.6995 7.08667C13.6995 7.10965 13.6995 7.13262 13.5995 7.15461V7.20761Z" fill="#E30613"/>
                                                    </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 mt-4 d-flex justify-content-center align-items-baseline min-selection-box">



                                    <div class="card-content d-flex flex-row justify-content-center align-items-center" [ngStyle]="{'border': signRequest.infoBancaire.jourPrelevement === 15 ? '1px solid #E30613' : 'none'}" (click)="signRequest.infoBancaire.jourPrelevement = 15">
                                        <span class="title">le 15 du mois</span>
                                        <div class="icon-container">
                                            <span class="min-checkbox-selected-hide" *ngIf="signRequest.infoBancaire.jourPrelevement === 15" [ngClass]="{'min-checkbox-selected': signRequest.infoBancaire.jourPrelevement === 15}">
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.00033 0.700504C7.22062 0.698505 5.48092 1.22512 4.01116 2.21437C2.5314 3.20263 1.38153 4.60858 0.701647 6.25234C-0.00823594 7.8881 -0.188206 9.70767 0.201729 11.4484C0.531675 13.186 1.36156 14.7879 2.60136 16.0529C3.87115 17.294 5.46086 18.1483 7.20058 18.5141C8.51036 18.7849 9.86022 18.7599 11.16 18.4411C12.4598 18.1234 13.6695 17.5198 14.6993 16.6744C15.7392 15.8291 16.5691 14.7639 17.139 13.5568C17.7089 12.3487 18.0088 11.0297 17.9988 9.6937C18.0188 8.5096 17.7789 7.33552 17.299 6.25234C16.8691 5.16016 16.2292 4.16787 15.3993 3.3345C14.5294 2.51012 13.5096 1.84963 12.3998 1.38498C11.33 0.923333 10.1701 0.690511 9.00033 0.700504ZM13.5995 7.20761L7.80057 13.0142C7.74058 13.0861 7.66051 13.1451 7.58052 13.1861C7.49053 13.227 7.40064 13.249 7.30066 13.252C7.20067 13.249 7.11066 13.226 7.02067 13.184C6.94069 13.1411 6.86073 13.0811 6.80074 13.0062L4.40111 10.584C4.40111 10.568 4.40113 10.5491 4.30115 10.5281V10.3942C4.40113 10.3732 4.40111 10.3542 4.40111 10.3382L5.20091 9.57081V9.53281C5.20091 9.52382 5.30099 9.51885 5.30099 9.51885V9.53281C5.40097 9.54081 5.40095 9.55382 5.40095 9.57081L7.30066 11.4903L12.5997 6.17738C12.5997 6.16039 12.5997 6.1474 12.6997 6.13841V6.12542C12.6997 6.12542 12.7998 6.12538 12.7998 6.13737V6.18134L13.5995 6.96377V7.0197C13.6995 7.04068 13.6995 7.06368 13.6995 7.08667C13.6995 7.10965 13.6995 7.13262 13.5995 7.15461V7.20761Z" fill="#E30613"/>
                                                    </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="d-flex flex-row justify-content-center align-items-center mt-3 " *ngIf="signRequest.infoBancaire.mode_paiement == 'iban' && signRequest.infoBancaire.periodicite">
                <div class="container">
                    <div class="row justify-content-center align-items-center mt-3 formred">
                        <div class="col-lg-10 col-md-12  align-items-center justify-content-center">
                            <form #souscripteurForm="ngForm">
                                <div class="d-flex row justify-content-center align-items-baseline mt-3 ">
                                    <div class="col-lg-6 col-sm-12">
                                        <div class="field-container">
                                            <mat-label><span class="text-danger">*</span> Nom de la banque</mat-label>
                                            <mat-form-field class="field-element" appearance="outline">
                                                <input matInput name="nomBanque" [(ngModel)]="signRequest.infoBancaire.nomBanque" #nomBanque="ngModel" required>
                                                <mat-error *ngIf="nomBanque.invalid && (nomBanque.dirty || nomBanque.touched)">
                                                    <mat-error *ngIf="nomBanque.errors?.required">
                                                        Nom de la banque est requis.
                                                    </mat-error>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12">
                                        <div class="field-container">
                                            <mat-label><span class="text-danger">*</span> Nom & Prénom titulaire du compte
                                            </mat-label>
                                            <mat-form-field class="field-element" appearance="outline">
                                                <input matInput name="titulaireCompte" [(ngModel)]="signRequest.infoBancaire.titulaireCompte" #titulaireCompte="ngModel" required>
                                                <mat-error *ngIf="titulaireCompte.invalid && (titulaireCompte.dirty || titulaireCompte.touched)">
                                                    <mat-error *ngIf="titulaireCompte.errors?.required">
                                                        Nom & Prénom titulaire du compte est requis.
                                                    </mat-error>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12">
                                        <div class="field-container">
                                            <mat-label><span class="text-danger">*</span>BIC</mat-label>
                                            <mat-form-field class="field-element" appearance="outline">
                                                <input matInput type="text" name="bic" [(ngModel)]="signRequest.infoBancaire.bic" #bic="ngModel" required placeholder="Code identification de la banque" pattern="[a-zA-Z0-9]*" (keydown)="onKeyDownInfosBancaire($event)">
                                                <mat-error *ngIf="bic.invalid && (bic.dirty || bic.touched)">
                                                    <mat-error *ngIf="bic.errors?.required">
                                                        BIC est requis.
                                                    </mat-error>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12">
                                        <div class="field-container">
                                            <mat-label><span class="text-danger">*</span>IBAN</mat-label>
                                            <mat-form-field class="field-element" appearance="outline">
                                                <input matInput type="text" name="iban" [(ngModel)]="signRequest.infoBancaire.iban" #iban="ngModel" required placeholder="Numéro identification international du compte" pattern="[a-zA-Z0-9]*" (keydown)="onKeyDownInfosBancaire($event)">
                                                <mat-error *ngIf="iban.invalid && (iban.dirty || iban.touched)">
                                                    <mat-error *ngIf="iban.errors?.required">
                                                        IBAN est requis.
                                                    </mat-error>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
 
        </div>
        <div class="navigations">
            <button mat-button class="btn-previous" (click)="previousStep()" [ngStyle]="{
                'background-color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[2],
                'color': 'white',
                'border': '1px solid ' + (orgInfo?.organisme?.brand.parcours_souscription.couleurs[2])
            }" >Retour</button>

            <button mat-button class="btn-next mb-3" 
            [ngStyle]="{
                'background-color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[3],
                'color': 'white',
                'border': '1px solid ' + (orgInfo?.organisme?.brand.parcours_souscription.couleurs[2])
            }" 
            (click)="sign(true)">
            <i class="fas fa-check mr-2" 
               [ngStyle]="{'color': orgInfo?.organisme?.brand.parcours_souscription.couleurs[0]}">
            </i>
            Signer
        </button>
        
        

        </div>
    </div>

</div>
</div>
<!--  -->
<!-- <div id="yousign" *ngIf="usign">
    <div class="header">
        <img src="/assets/svg-b2c/asolutionbleu.svg" alt="">
    </div>
    <div>
        <iframe style="width:100%;height:87vh;border:none;" src="https://staging-app.yousign.com/procedure/sign?members=%2Fmembers%2F36fc67f3-389a-46fa-8748-800e04f34ef6"></iframe>
    </div>
</div> -->