import { Component, OnInit , EventEmitter, Output, Input, Renderer2, ElementRef } from '@angular/core';
import { Options } from "@angular-slider/ngx-slider";
import { Questions } from './_models/Questions';
import { ErrorDialogService } from 'src/app/shared-components/error-dialog/error.dialog.service';
import { OrgInfoService } from 'src/app/services/organisme/org-info.service';

@Component({
  selector: 'questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  @Output() emitQuestions: EventEmitter<any> = new EventEmitter();
  msgInfo: string = "Nous sommes désolés, nous ne pouvons pas assurer ce type d'habitation !";
  @Input() questions : Questions = new Questions() ;
  @Input() isQuestionsClicked : boolean = false
  @Input() typeHabitation : string = "" ; 
  value: number = 6000;
  orgInfo : any

  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 6000, legend: "6000€" },
      { value: 9000, legend: "9000€"},
      { value: 15000, legend: "15000€"},
      { value: 30000, legend: "30000€"},
      { value: 50000, legend: "50000€" },
      { value: 80000, legend: "80000€"},
      { value: 100000, legend: "100000€" },
      { value: 125000, legend: "125000€" },
      { value: 150000, legend: "150000€" },
    ]
  };

  textNiveauProtection : string = "- Porte d'accès : Ce sont tant les portes donnant un accès de l'extérieur que celles communiquant entre l'habitation et une véranda ou un garage ou un sous-sol non protégé par une porte correspondant au niveau de protection retenu.\n\n - Point de condamnation : Serrure et tout système de fermeture à clé sauf cadenas.\n\n - Les portes secondaires, dépourvues de parties vitrées, pourront être équipées d'une barre horizontale posée sur étriers et bloquées par un cadenas, d'un verrou (sauf targette à coulisse), fléaux, loquets, espagnolettes." ; 
  constructor(private _errorService: ErrorDialogService,
    private orgInfoService: OrgInfoService,private renderer: Renderer2, private el: ElementRef) {}


  ngOnInit(): void {
    if(this.questions.capital_mobilier)
    this.value = this.questions.capital_mobilier;

    this.validationInputs() ;
    this.orgInfoService.orgInfo$.subscribe((info) => {
      this.orgInfo = info;
      const color = this.orgInfo?.organisme?.brand.parcours_souscription.couleurs[0];
      this.renderer.setStyle(document.documentElement, '--slider-pointer-color', color);
      const selectedStepIcon = this.el.nativeElement.querySelector('.ngx-slider-selection');
      const ngxSliderPointer = this.el.nativeElement.querySelector('.ngx-slider-pointer');
  
      const dynamicColor = this.orgInfo?.organisme?.brand.parcours_souscription.couleurs[0];

      if (selectedStepIcon && dynamicColor) {
        selectedStepIcon.setAttribute(
          'style', 
          `background-color: ${dynamicColor} !important; border: solid 1px ${dynamicColor} !important;`
        );
      }

      if (ngxSliderPointer && dynamicColor) {
        ngxSliderPointer.setAttribute(
          'style', 
          `background-color: ${dynamicColor} !important; border: solid 1px ${dynamicColor} !important;`
        );
      }
    console.log(info)
    }); 
  }
  get sliderPointerColor() {
    return this.orgInfo?.organisme?.brand.parcours_souscription.couleurs[0] || 'white';  
}

  getSlideValue(value: boolean, availableValue: boolean,attr:string,reverse:boolean): void{
    this.questions[attr] = value;
    if (!reverse) {
      if(this.questions[attr] && !availableValue){
        this.showAlertInfo();
      }
    } else {
      if(!this.questions[attr] && !availableValue){
        this.showAlertInfo();
      }
    }
  }

  getInputNumberValue(event: number,attr:string): void{
    this.questions[attr] = event ;

    this.validationInputs() ;
    if (attr == "surface_kiosque") {
      if (!this.validateSurfaceKiosque())  {
        this._errorService.openErrorDialog("Nous sommes désolés, la surface de votre kiosque ne peut pas faire l'objet d'une garantie !", 'Fermer',true);
      }
    }
  }

  validateSurfaceKiosque() {
    if (this.questions.surface_kiosque !== null && this.questions.surface_kiosque > 40 ) {
      return false
    } else {
      return true ;
    }
  }

  showAlertInfo(): void{
    this._errorService.openErrorDialog(this.msgInfo, 'Fermer',true);
  }

  validationInputs() :void{
    this.emitQuestions.emit(
      {
        questions: this.questions,
        valid: this.validateRequiredInputs(),
        validateSurfaceKiosque: (this.questions.kiosque === true && this.questions.surface_kiosque !== null) ? this.validateSurfaceKiosque() : null ,
      }
    )
  }

  onValueChange(event: any) {
    if (event !== undefined) {
      this.value = event
      this.questions.capital_mobilier = this.value ;
      this.questions.capital_vol = this.questions.capital_mobilier / 2
    }
  }

  selectNiveauProtection(niveau: number) {
    this.questions.niveau_protection = niveau ;
    if (this.questions.niveau_protection === 1) {
      this.showAlertInfo() ;
    }
  }

  selectFormule(formule: string) {
    this.questions.Formule = formule ; 
  }

  getHasAlaramValue(event: any) {
    this.questions.alarme = event.target.checked ;
  }

  validateRequiredInputs(): boolean {
    if (this.questions.niveau_protection === null) {
      return false ;
    } else if (this.questions.capital_vol > 50000 && this.questions.alarme !== true && this.questions.place_alarme === null) {
      return false ;
    } else if (this.questions.terrasse === true && this.questions.surface_terrasse === null) {
      return false ;
    } else if (this.questions.kiosque === true && this.questions.surface_kiosque === null) {
      return false ;
    } else {
      return true ;
    }
  }

}
