import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import { OrgInfoService } from 'src/app/services/organisme/org-info.service';



@Component({
  selector: 'card-checkbox',
  templateUrl: './card-checkbox.component.html',
  styleUrls: ['./card-checkbox.component.scss']
})
export class CardCheckboxComponent implements OnInit {
 @Input() title: string = '';
@Input() isChecked: boolean = false;
@Input() coloredIcons: number = 0;
@Input() greyIcons: number = 0;
@Input() isSelected: boolean = false;
@Input() index:number=0;
@Output() cardSelected = new EventEmitter<string>();
showCheckbox: boolean = false;
orgInfo : any

 medalicon='<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">'+
'<path opacity="0.4" d="M19.5 9C19.5 10.45 19.07 11.78 18.33 12.89C17.25 14.49 15.54 15.62 13.55 15.91C13.21 15.97 12.86 16 12.5 16C12.14 16 11.79 15.97 11.45 15.91C9.46 15.62 7.75 14.49 6.67 12.89C5.93 11.78 5.5 10.45 5.5 9C5.5 5.13 8.63 2 12.5 2C16.37 2 19.5 5.13 19.5 9Z" fill="#292371"/>'+
'<path d="M21.7501 18.4699L20.1001 18.8599C19.7301 18.9499 19.4401 19.2299 19.3601 19.5999L19.0101 21.0699C18.8201 21.8699 17.8001 22.1099 17.2701 21.4799L12.5001 15.9999L7.73008 21.4899C7.20008 22.1199 6.18008 21.8799 5.99008 21.0799L5.64008 19.6099C5.55008 19.2399 5.26008 18.9499 4.90008 18.8699L3.25008 18.4799C2.49008 18.2999 2.22008 17.3499 2.77008 16.7999L6.67008 12.8999C7.75008 14.4999 9.46008 15.6299 11.4501 15.9199C11.7901 15.9799 12.1401 16.0099 12.5001 16.0099C12.8601 16.0099 13.2101 15.9799 13.5501 15.9199C15.5401 15.6299 17.2501 14.4999 18.3301 12.8999L22.2301 16.7999C22.7801 17.3399 22.5101 18.2899 21.7501 18.4699Z" fill="#292371"/>'+
'<path d="M13.08 5.98L13.67 7.15999C13.75 7.31999 13.96 7.48 14.15 7.51L15.22 7.68999C15.9 7.79999 16.06 8.3 15.57 8.79L14.74 9.61998C14.6 9.75998 14.52 10.03 14.57 10.23L14.81 11.26C15 12.07 14.57 12.39 13.85 11.96L12.85 11.37C12.67 11.26 12.37 11.26 12.19 11.37L11.19 11.96C10.47 12.38 10.04 12.07 10.23 11.26L10.47 10.23C10.51 10.04 10.44 9.75998 10.3 9.61998L9.46997 8.79C8.97997 8.3 9.13997 7.80999 9.81997 7.68999L10.89 7.51C11.07 7.48 11.28 7.31999 11.36 7.15999L11.95 5.98C12.24 5.34 12.76 5.34 13.08 5.98Z" fill="#292371"/>'+
'</svg>'

medalicongrey ='<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">'+
'<path d="M21.7502 18.4701L20.1002 18.8601C19.7302 18.9501 19.4402 19.2301 19.3602 19.6001L19.0102 21.0701C18.8202 21.8701 17.8002 22.1201 17.2702 21.4901L14.2802 18.0501C14.0402 17.7701 14.1702 17.3301 14.5302 17.2401C16.3002 16.8101 17.8902 15.8201 19.0602 14.4101C19.2502 14.1801 19.5902 14.1501 19.8002 14.3601L22.0202 16.5801C22.7802 17.3401 22.5102 18.2901 21.7502 18.4701Z" fill="#DCDCDC"/>'+
'<path d="M3.20016 18.4701L4.85016 18.8601C5.22016 18.9501 5.51016 19.2301 5.59016 19.6001L5.94016 21.0701C6.13016 21.8701 7.15016 22.1201 7.68016 21.4901L10.6702 18.0501C10.9102 17.7701 10.7802 17.3301 10.4202 17.2401C8.65016 16.8101 7.06016 15.8201 5.89016 14.4101C5.70016 14.1801 5.36016 14.1501 5.15016 14.3601L2.93016 16.5801C2.17016 17.3401 2.44016 18.2901 3.20016 18.4701Z" fill="#DCDCDC"/>'+
'<path d="M12.5 2C8.63 2 5.5 5.13 5.5 9C5.5 10.45 5.93 11.78 6.67 12.89C7.75 14.49 9.46 15.62 11.45 15.91C11.79 15.97 12.14 16 12.5 16C12.86 16 13.21 15.97 13.55 15.91C15.54 15.62 17.25 14.49 18.33 12.89C19.07 11.78 19.5 10.45 19.5 9C19.5 5.13 16.37 2 12.5 2ZM15.56 8.78L14.73 9.61C14.59 9.75 14.51 10.02 14.56 10.22L14.8 11.25C14.99 12.06 14.56 12.38 13.84 11.95L12.84 11.36C12.66 11.25 12.36 11.25 12.18 11.36L11.18 11.95C10.46 12.37 10.03 12.06 10.22 11.25L10.46 10.22C10.5 10.03 10.43 9.75 10.29 9.61L9.44 8.78C8.95 8.29 9.11 7.8 9.79 7.69L10.86 7.51C11.04 7.48 11.25 7.32 11.33 7.16L11.92 5.98C12.24 5.34 12.76 5.34 13.08 5.98L13.67 7.16C13.75 7.32 13.96 7.48 14.15 7.51L15.22 7.69C15.89 7.8 16.05 8.29 15.56 8.78Z" fill="#DCDCDC"/>'+
'</svg>'

  handleClick() {
    let bienName = "";
    switch (this.index) {
      case 1: bienName = "Vétuste"; break;
      case 2: bienName = "Moyen"; break;
      case 3: bienName = "Correct"; break;
      case 4: bienName = "Bon"; break;
    }
    this.cardSelected.emit(bienName);
    if(this.index == 1) return;
    if(!this.isSelected)
    this.isSelected = !this.isSelected;
    if(!this.showCheckbox)
    this.showCheckbox = !this.showCheckbox;
  }

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private orgInfoService: OrgInfoService


  ) {
    iconRegistry.addSvgIconLiteral('medalicon', sanitizer.bypassSecurityTrustHtml(this.medalicon));
    iconRegistry.addSvgIconLiteral('medalicongrey', sanitizer.bypassSecurityTrustHtml(this.medalicongrey));
   }


   getColoredIcons(): number[] {
    return Array.from({ length: this.coloredIcons }, (_, index) => index + 1);
  }

  getGreyIcons(): number[] {
    return Array.from({ length: this.greyIcons }, (_, index) => index + 1);
  }


  ngOnInit(): void {
    this.orgInfoService.orgInfo$.subscribe((info) => {
      this.orgInfo = info;
    console.log(info)
    }); 
  }

}
