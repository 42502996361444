import { Component, OnInit } from '@angular/core';
 import { Router, ActivatedRoute } from '@angular/router';  
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { OrgInfoService } from 'src/app/services/organisme/org-info.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  partnershipId: string = '';  
  submitted = false;
  show_loading_add: boolean = false;
 

  constructor(
    private router: Router,
    private route: ActivatedRoute ,
    private AuthentificationService : ApiAuthentificationService,
    private orgInfoService: OrgInfoService
 
  ) { }

  ngOnInit(): void {
     this.route.queryParamMap.subscribe(params => {
      this.partnershipId = params.get('partnership_id') ?? '';  
      if (this.partnershipId) {
        this.partnerShipData();  
      } else {
        this.router.navigate(['/forbidden']);  
      }
    });
  }



  
  partnerShipData(){
    
  }

  getOrganisme(idOrg : string){
    this.AuthentificationService.GetOrganismeById(idOrg).subscribe((response)=>{
 

    },
     (errorResponse)=>{

      console.log(errorResponse)
    })
  }


  

}
