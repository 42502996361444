<ng-container>
    <router-outlet></router-outlet>
    <button #clearButton
    class="clearButton" 
  (click)="clear()" 
  [style.background-color]="this.orgInfo?.organisme?.brand.parcours_souscription.couleurs[0]"
  matTooltip="Si vous continuez, toutes vos données préremplies seront perdues !" 
  matTooltipPosition="left">
    <mat-icon class="reset-icon">restart_alt</mat-icon>
</button>

    <div class="meteo-slide" [@meteoSlideWidth]="meteoSlideWidth">
    <div class="meteo-container" [@slideInOut]="meteoFrameStatus">
        <iframe class="meteo"src="https://vigilance.meteofrance.fr/fr/widget-vigilance/vigilance/reunion" frameborder="0">
        </iframe>
    </div>

    <button class="toggle-button" (click)="toggleMeteo()" [@buttonSlide]="buttonSlide" matTooltip="Aucune souscription pendant les périodes d'alertes cyclone ou tempête (dès connaissance de l'évènement)">
        <mat-icon class="cloud-icon">cloud</mat-icon>
        <mat-icon class="toggle-icon">{{ meteoFrameStatus === 'in' ? 'chevron_left' : 'chevron_right' }}</mat-icon>
    </button>
    </div>
</ng-container>