import { ErrorDialogService } from 'src/app/shared-components/error-dialog/error.dialog.service';
import { ComplementaryInformation } from './_models/ComplementaryInfo';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { OrgInfoService } from 'src/app/services/organisme/org-info.service';

export const DATE_FORMAT = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
      dateInput: 'YYYY',
      monthYearLabel: 'YYYY',
      monthYearA11yLabel: 'YYYY',
      dateA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'complementary-information',
  templateUrl: './complementary-information.component.html',
  styleUrls: ['./complementary-information.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT},
  ],
})
export class ComplementaryInformationComponent implements OnInit, OnChanges {
  @Output() emitComplementaryInfo: EventEmitter<any> = new EventEmitter();
  @Input() complementaryInformation: ComplementaryInformation = new ComplementaryInformation();
  @Input() isComplemnetaryInformationClicked: boolean = false;
  msgInfo: string = "Nous sommes désolés, nous ne pouvons pas assurer ce type d'habitation !";
  titleBien: string = "La vétusté d'un logement est son usure causée par le temps et l'usage normal dont il est fait. Cette vétusté s'apprécie sur la base de plusieurs critères à savoir l'ancienneté du bâti, son usage et la qualité de son entretien.";
  titleHabitation: string = "Les bâtiments construits en béton, briques, bois sont des systèmes de construction y compris toiture solide et durable. A l'inverse, les bâtiments dont la construction ou la couverture comporte des plaques de toutes natures, non posées et non fixées selon les règles de l'art ou des bâtiments clos au moyen de baches ou des matériaux tels que carton, ou feutre bitumé, toile ou papier goufronné non fixés selon les règles de l'art ne sont pas considérés comme des bâtiments en dur.";
  selectYear = new FormControl();
  dateNow = new Date();
  orgInfo: any;
  
  constructor(private _errorService: ErrorDialogService,
    private orgInfoService: OrgInfoService) { }



  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.isComplemnetaryInformationClicked?.currentValue){
      this.selectYear.markAsTouched()
    }
  }

  ngOnInit(): void {
    if(this.complementaryInformation.annee_construction){
      this.selectYear = new FormControl(moment("01-01-"+this.complementaryInformation.annee_construction))
      this.validationInputs()
    }  


    this.orgInfoService.orgInfo$.subscribe((info) => {
      this.orgInfo = info;
    console.log(info)
    });
  }


  onCardSelected(bienName: string) {
    this.complementaryInformation.etat_bien = bienName;
    if(this.complementaryInformation.etat_bien == "Vétuste")
    this._errorService.openErrorDialog(this.msgInfo, 'Fermer',true);
    else
    this.validateConditionEtatAge(this.complementaryInformation.annee_construction) ;
    // if(bienName == "Vétuste")
    // this._errorService.openErrorDialog(this.msgInfo, 'Fermer');
    // else
    // this.complementaryInformation.etat_bien = bienName;
  }

  showAlertInfo(): void{
    this._errorService.openErrorDialog(this.msgInfo, 'Fermer',true);
  }

  getSlideValue(value: boolean, availableValue: boolean,attr:string,reverse:boolean): void{
    this.complementaryInformation[attr] = value;
    if (!reverse) {
      if(this.complementaryInformation[attr] && !availableValue){
        this.showAlertInfo();
      }
    } else {
      if(!this.complementaryInformation[attr] && !availableValue){
        this.showAlertInfo();
      } 
    }


    // if(!value && !availableValue){
    //   this.showAlertInfo();
    // }
  }

  validationInputs() :void{
    this.emitComplementaryInfo.emit(
      {
        complementaryInfo: this.complementaryInformation,
        validateEtatAge: (this.complementaryInformation.annee_construction && this.complementaryInformation.etat_bien) ? this.validateConditionEtatAgeSub(this.complementaryInformation.annee_construction) : false ,
        valid: (this.complementaryInformation.annee_construction !== null && this.complementaryInformation.etat_bien !== "") ? true : false
      }
    )
  }

  chosenYearHandlerFilter(normalizedYear: Moment, dp: any) {
    this.selectYear.setValue(normalizedYear)
    dp._destroyPopup();
    // if(this.myFilter(normalizedYear) && this.complementaryInformation.etat_bien == "Moyen")
    // this.showAlertInfo()
    // else
    this.complementaryInformation.annee_construction = normalizedYear.year()
    // if (this.complementaryInformation.etat_bien != "")
    this.validateConditionEtatAge(this.complementaryInformation.annee_construction) ;

    this.validationInputs()

  }

  getDateValue(event: any): void{
    this.complementaryInformation.annee_construction = parseInt(this.selectYear.value.year());
    // if (this.complementaryInformation.etat_bien != "")
    this.validateConditionEtatAge(this.complementaryInformation.annee_construction) ;

    // const year = parseInt(event.target.value);
    // if(new Date().getFullYear() - year <= 20)
    // this.complementaryInformation.annee_construction = parseInt(event.target.value);
    // else
    // this.showAlertInfo()
  }

  onInputDateValue(event: any){
    let inputValue = event.target.value;
      // Remove any non-numeric characters
      inputValue = inputValue.replace(/\D/g, '');
      if(inputValue && inputValue.length == 4) {
        this.selectYear = new FormControl(moment("01-01-"+inputValue))
        this.complementaryInformation.annee_construction = parseInt(this.selectYear.value.year());
      } else {
        if(inputValue.length ==0){
          this.selectYear.reset()
          this.complementaryInformation.annee_construction = null;
        }else if(inputValue.length > 4){
          inputValue = inputValue.substring(0, 4);
          this.selectYear = new FormControl(moment("01-01-"+inputValue))
          this.complementaryInformation.annee_construction = parseInt(this.selectYear.value.year());
        }
      }

  }

  validateConditionEtatAgeSub(year:number | null) {
    if (year !== null) {
      if(this.myFilter(year) && this.complementaryInformation.etat_bien == "Moyen") {
        return false ;
      } else {
        return true ;
      }

    } else {
      return false ;
    }

  }

  validateConditionEtatAge(year:number | null) {
    if (year !== null) {
      if(this.myFilter(year) && this.complementaryInformation.etat_bien == "Moyen") {
        this.showAlertInfo()
        return false ;
      } else {
        return true ;
      }

    } else {
      return false ;
    }

  }

  myFilter = (year: number): boolean => {
    return  20 <= this.dateNow.getFullYear() - year;

    // if(!d){
    //   return true
    // }
    // const year = d.year()
    // return  20 <= this.dateNow.getFullYear() - year;
  };


  onKeyDownDate(event: KeyboardEvent): void {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight'];
    if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
      return;
    }
    if (allowedKeys.indexOf(event.key) === -1) {
      event.preventDefault();
    }
  }

  getSlideValueCertificate(event : any) {
    this.complementaryInformation.resilie = event; 
    if (this.complementaryInformation.resilie  == false) {
      this.complementaryInformation.certificat_file = null ; 
    }
    this.validationInputs() ; 
  }
}
